.TopbarContainer {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 60px;
	background-color: #4c4c4c;
	justify-content: space-between;
	color: white;
	z-index: 9;
}

.TopbarLeft {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin: 0px;
	padding: 0px;
	width: 200px;
}

.TopbarRight {
	background-color: #f0f0f0;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin: 10px;
	justify-self: end;
	align-items: center;
}

.TopbarLeftColumn1 {
	margin: 10px;
	width: fit-content;
	justify-self: start;
	text-align: left;
	width: 50px;
}

.TopbarLeftColumn2 {
	width: fit-content;
	margin-right: 10px;
	justify-self: start;
	width: 50px;
}

.TopbarLeftColumn3 {
	display: grid;
	grid-template-columns: 1fr;
	width: fit-content;
	justify-self: start;
	align-items: center;
	margin: 0px;
	padding: 0px;
}

.TopbarLogo1 {
	width: fit-content;
	justify-self: start;
	margin-top: 3px;
}

.TopbarLogo2 {
	font-size: 12px;
	width: fit-content;
	justify-self: start;
	margin: 0px;
	padding: 0px;
	margin-top: 3px;
}

.TopbarLogo3 {
	width: fit-content;
	justify-self: start;
	font-size: 40px;
	font-weight: bold;
	margin-top: -10px;
	padding: 0px;
}

.TopbarRight2 {
	margin-right: 10px;
}

.TopbarInitialsContainer {
	display: flex;
	align-items: center;
	justify-content:center;
	height: 40px;
	//border: solid 1px;
}

.TopbarInitials {
	color: grey;
	font-size: 20px;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	text-transform: uppercase;
}

