.uploadFiles{
  background-color: green;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 120px;
  margin: 4px 2px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 600px;
}

.resetFiles{
  background-color: red;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 120px;
  margin: 4px 2px;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 600px;
}