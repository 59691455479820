/* Centered Controls Row */
.controls-row {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  gap: 1rem; /* Space between elements */
  margin-bottom: 1rem;
}

.button-container0 {
  display: flex;
  flex-wrap: wrap; /* Allows buttons to wrap to the next line */
  gap: 16px; /* Space between buttons */
  justify-content: center; /* Center-aligns buttons */
  padding: 20px;
  max-width: 100%; /* Ensures container doesn't exceed viewport width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Optional: Center container itself if needed */
.button-container0-wrapper {
  display: flex;
  justify-content: center; /* Center-aligns the entire button container */
  width: 100%;
}

.billButton0 {
  background-color: white; /* White background */
  color: black; /* Black text color */
  border: 2px solid black; /* Black border with some width */
  border-radius: 12px; /* More rounded corners */
  padding: 20px; /* Increased padding for larger button */
  width: 300px; /* Fixed width for the button */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 18px; /* Larger font size for text */
  line-height: 1.6; /* Increased line-height for better readability */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes date to the bottom */
  align-items: flex-start; /* Aligns content to the start of the button */
}

.billButton0:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  border-color: #333; /* Darker border color on hover */
}

.billButton0:active {
  background-color: #e0e0e0; /* Slightly darker grey background on click */
  border-color: #222; /* Even darker border color on click */
}

.seller-name0 {
  font-size: 24px; /* Largest size for seller name */
  font-weight: bold; /* Bold text */
  color: black; /* Black text color */
}

.date0 {
  font-size: 18px; /* Size for date text */
  color: black; /* Black text color */
}

.total-price0 {
  font-size: 20px; /* Medium size for total price */
  font-weight: bold; /* Bold text */
  color: black; /* Black text color */
  align-self: flex-end; /* Aligns total price to the end of the button */
}

/* Container style for single-button rows */
.button-container1 {
  display: flex;
  flex-direction: column; /* Stacks buttons vertically */
  align-items: center; /* Center-aligns buttons horizontally */
  padding: 20px;
  max-width: 100%; /* Ensures container doesn't exceed viewport width */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

/* Button style for wide buttons with columns */
.billButton1 {
  background-color: #fafafa; /* Light grey background */
  color: #333; /* Dark text color */
  border: 2px solid #ddd; /* Light grey border */
  border-radius: 8px; /* Less rounded corners */
  padding: 20px; /* Padding inside the button */
  width: 100%; /* Full width of its container */
  max-width: 800px; /* Max width for large screens */
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Three columns with different widths */
  gap: 10px; /* Space between columns */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.billButton1:hover {
  background-color: #eaeaea; /* Slightly darker grey on hover */
  border-color: #bbb; /* Darker border color on hover */
}

.billButton1:active {
  background-color: #dcdcdc; /* Even darker grey on click */
  border-color: #aaa; /* Even darker border color on click */
}

.seller-name1,
.date1,
.total-price1 {
  display: flex; /* Flexbox to align items within each column */
  align-items: center; /* Center-aligns items vertically */
  justify-content: center; /* Center-aligns items horizontally */
  color: #333; /* Dark text color */
}

.seller-name1 {
  font-size: 20px; /* Size for seller name */
  font-weight: bold; /* Bold text */
}

.date1 {
  font-size: 16px; /* Size for date text */
}

.total-price1 {
  font-size: 18px; /* Size for total price */
  font-weight: bold; /* Bold text */
}

.center-container {
  display: flex;
  justify-content: center; /* Centers the child elements horizontally */
}

.changeFormat {
  background: linear-gradient(45deg, #007BFF, #00C6FF); /* Gradient background */
  color: white; /* White text color for contrast */
  border: none; /* Remove default border */
  border-radius: 30px; /* Rounded corners */
  padding: 12px 24px; /* Padding for larger button */
  font-size: 16px; /* Medium font size */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.changeFormat:hover {
  background: linear-gradient(45deg, #00C6FF, #007BFF); /* Reverse gradient on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Increase shadow on hover */
}

.changeFormat:active {
  transform: scale(0.98); /* Slightly shrink the button when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Reduce shadow */
}

/* Search Input, Price Input */
.search-input,
.price-input {
  padding: 8px;
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Sort Buttons */
.sort {
  position: relative;
  padding: 10px 20px;
  padding-right: 40px; /* Add padding to make space for the icon */
  background: linear-gradient(45deg, #007BFF, #00C6FF); /* Gradient background */
  border: none;
  border-radius: 30px; /* Rounded corners */
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease; /* Smooth transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.sort:hover {
  background: linear-gradient(45deg, #00C6FF, #007BFF); /* Reverse gradient on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
}

.sort:active {
  transform: scale(0.98); /* Slightly shrink the button when clicked */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduce shadow */
}

.sort.asc::after {
  content: '⌄';
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #FFF; /* White icon */
}

.sort.desc::after {
  content: '⌃';
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #FFF; /* White icon */
}

.sort.neutral::after {
  content: '=';
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #FFF; /* White icon */
}

.controls-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.pagination-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.pagination-button.active {
  background-color: #007BFF;
  color: white;
}

.pagination-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-input {
  width: 50px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}
