/* Products Table */
.product-table {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 16px; /* Font size for better readability */
	text-align: left;
  }
  
  .product-table th, .product-table td {
	padding: 10px 15px; /* Padding for readability */
	border: 1px solid #ddd;
  }
  
  .product-table th {
	background-color: #f0f0f0;
	color: black;
	font-weight: bold;
	text-align: left;
  }
  
  .product-table td {
	color: #333;
  }
  
  /* Alternating row colors for contrast */
  .product-table tr:nth-child(even) {
	background-color: #f9f9f9; /* Light background for even rows */
  }
  
  .product-table tr:nth-child(odd) {
	background-color: #e6e6e6; /* Slightly darker background for odd rows */
  }
  
  /* Hover effect to make rows stand out when hovered */
  .product-table tr:hover {
	background-color: #dcdcdc;
  }
  
  /* Footer styling for total price row */
  .product-table tfoot {
	background-color: #e0e0e0; /* Light background for footer */
  }
  
  .product-table .footer-text {
	text-align: right;
	font-weight: bold;
  }
  
  .product-table .footer-price {
	font-weight: bold;
	text-align: right;
	color: #333;
  }
  